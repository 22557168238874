<template>
  <div class="recite">
    <div class="header">
      <div class="title">
        （必背）2022教资高频考点梳理
      </div>
      <div class="sub-title">
        凯威老师 X 爱答教育
      </div>
    </div>
    <div class="main">
      <div v-for="(paragraph, i) in article.paragraphs" :key="i" class="paragraph">
        <span v-for="(text, j) in paragraph.units" :key="j">
          <span
            :class="{
              hide: text.display == false,
              red: text.mark && text.display,
            }"
            @click="onSwitch(text)"
            >{{ text.text }}</span
          >
        </span>
      </div>
    </div>

    <div class="footer">
      <div class="left">
        <button class="recite-mode btn" :class="{ active: reciteMode }" @click="onReciteMode">
          背诵模式
        </button>
        <button class="text-mode btn" :class="{ active: !reciteMode }" @click="onReadMode">
          速记模式
        </button>
      </div>
      <div class="right">
        <button class="download btn active" @click="showCsWidget = true">下载完整版</button>
      </div>
      <wechatId v-if="showCsWidget" :title="'加老师微信领资料'"></wechatId>
    </div>
  </div>
</template>

<script>
import { TouchEmulator } from "@/util/touch.js";
import wechatId from "@/components/wechatId.vue";
import { bannerApi } from "@/api/bannerApi.js";
export default {
  components: {
    wechatId,
  },
  data() {
    return {
      clickCount: 0,
      showCsWidget: false,
      reciteMode: true,
      paragraphs: [],
      article: {
        paragraphs: [],
      },
      showGoodReputation: true,
    };
  },
  methods: {
    onSwitch(text) {
      if (text.mark) {
        text.display = !text.display;
      }
      this.clickCount++;
    },
    onReciteMode() {
      this.reciteMode = true;
      this.mark(false);
      this.clickCount++;
    },
    onReadMode() {
      this.reciteMode = false;
      this.mark(true);
      this.clickCount++;
    },
    mark(val) {
      // true 可见 false 不可见,
      for (let paragraph of this.article.paragraphs) {
        for (let unit of paragraph.units) {
          if (unit.mark) {
            unit.display = val;
          }
        }
      }
    },
    getBanner() {
      bannerApi.recite().then((ret) => {
        if (ret.code == 0 && ret.data) {
          this.article = ret.data;
          // this.paragraphs = ret.data.paragraphs;
          // this.paragraphs = [];
          // for (let p of ret.data.paragraphs) {
          //     let units = [];
          //     for (let unit of p.units) {
          //         units.push(unit);
          //     }
          //     this.paragraphs.push(units);
          // }
        }
      });
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
  },
  mounted() {
    this.listenOnCloseCsWidget();
    this.getBanner();
    if (this.$route.query && this.$route.query.hideGood && this.$route.query.hideGood == 1) {
      this.showGoodReputation = false;
    }
  },
  watch: {
    clickCount: function(val) {
      if (val % 4 == 0) {
        this.showCsWidget = true;
      }
    },
  },
  beforeCreate() {
    document.title = "（必背）2022教资高频考点梳理";
  },
};
</script>

<style lang="less" scoped>
.recite {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
  //align-items: center;
  .header {
    display: flex;
    flex-direction: column;
    height: 238px;
    background: linear-gradient(180deg, rgb(143, 112, 255) 0%, rgb(62, 67, 202) 100%);
    flex: 0 0 auto;
    .title {
      margin: 20px 0 8px 0;
      font-size: 1.6rem;
      font-weight: 700;
      color: #ffffff;
      text-align: center;
    }
    .sub-title {
      font-size: 1rem;
      color: #ffffff;
      text-align: center;
      font-weight: 500;
    }
  }
  .main {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    flex: 1 1 auto;
    // align-items: center;
    margin: -140px 20px 20px 20px;
    padding: 1rem;
    background: #ffffff;
    box-shadow: 0 0.1rem 0.2rem #eee;
    border-radius: 0.5rem;
    font-size: 1.14rem;
    font-weight: 500;
    color: #323233;
    letter-spacing: 0.4px;
    .hide {
      background: #fe611c;
      color: #fe611c;
    }
    .red {
      color: red;
    }
    .paragraph {
      margin: 6px 0px;
    }
  }
  .footer {
    margin: 0 20px 8px 20px;
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .btn {
      height: 2.7rem;
      font-size: 1rem;
      border: 1px solid #4d52d1;
      padding: 0 10px;
      border-radius: 0.2rem;
      background: #fff;
      color: #323233;
    }
    .active {
      background: #4d52d1;
      border: none;
      font-weight: 500;
      color: #ffffff;
    }
    .left {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    }
    .right {
      .download {
        width: 142px;
      }
    }
  }
}
</style>
