import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const recom = () => {
  return ajax.get(`${base}/api/itedu/v1/banner/recom`);
};

const query = (type) => {
  return ajax.get(`${base}/api/itedu/v1/banner/query?type=${type}`);
};

const recite = () => {
  return ajax.get(`${base}/api/itedu/v1/banner/recite`);
};

const jzInterview = () => {
  return ajax.get(`${base}/api/itedu/v1/banner/jz-mianshi`);
};

export const bannerApi = {
  // import时得花括号明确
  recom: recom,
  query: query,
  recite: recite,
  jzInterview: jzInterview,
};
